import { RouteRecordRaw } from "vue-router";

import AdminTemplate from "@/views/admin/_adminTemplateView.vue";
import { translate } from "@/lib/i18n-helpers";

const routes_admin: RouteRecordRaw = {
  path: "/admin",
  name: "admin",
  redirect: "/admin/",
  component: AdminTemplate,
  meta: { requiresAuth: true },
  children: [
    {
      path: "",
      name: "header.admin.management.indicators",
      component: () =>
        import("@/views/admin/management/indicators/IndicatorsView.vue"),
    },
    {
      path: "reserved-area",
      name: translate("header.admin.reserved_area"),
      meta: { external: true },
      children: [
        {
          path: "questionnaires",
          name: translate("header.admin.reserved_area.questionnaires"),
          component: () =>
            import(
              "@/views/admin/reserved-area/questionnaires/ReservedQuestionnairesView.vue"
            ),
        },
        {
          path: "questionnaires/:uuid",
          name: "questionnaire-respond(Reserved)",
          component: () =>
            import(
              "@/views/admin/reserved-area/questionnaires/AnswerQuestionnaireView.vue"
            ),
        },
        {
          path: "dashboards",
          name: translate("header.admin.reserved_area.dashboards"),
          component: () =>
            import(
              "@/views/admin/reserved-area/dashboards/ReservedDashboardsView.vue"
            ),
        },
      ],
    },
    {
      path: "management",
      children: [
        // questionnaires
        {
          path: "questionnaires",

          children: [
            {
              path: "",
              name: "header.admin.management.questionnaires",
              component: () =>
                import("@/views/admin/management/questionnaires/index.vue"),
            },
            {
              path: "create",
              name: "admin.management.questionnaires.steps.new",
              component: () =>
                import("@/views/admin/management/questionnaires/create.vue"),
            },
            {
              path: "edit/",
              redirect: "/admin/management/questionnaires/",
            },
            {
              path: "edit/:uuid",
              name: "admin.management.questionnaires.steps.edit",
              component: () =>
                import("@/views/admin/management/questionnaires/create.vue"),
            },
            {
              path: "translate/",
              redirect: "/admin/management/questionnaires/",
            },
            {
              path: "translate/:uuid",
              name: "admin.management.questionnaires.steps.translation",
              component: () =>
                import("@/views/admin/management/questionnaires/create.vue"),
            },
          ],
        },

        // users
        {
          path: "users",
          meta: { onlyAdmin: true },

          children: [
            {
              path: "",
              name: "header.admin.management.users",
              component: () =>
                import("@/views/admin/management/users/index.vue"),
            },
            {
              path: "create",
              name: translate("admin.management.questionnaires.steps.new"),
              component: () =>
                import("@/views/admin/management/users/UserForm.vue"),
            },
            {
              path: "edit/:uuid",
              name: translate("admin.management.questionnaires.steps.edit"),
              component: () =>
                import("@/views/admin/management/users/UserForm.vue"),
            },
          ],
        },

        {
          path: "entities",
          children: [
            {
              path: "",
              name: translate("admin.management.entities.title"),
              component: () =>
                import("@/views/admin/management/entities/EntitiesView.vue"),
            },
          ],
        },

        {
          path: "groups",
          children: [
            {
              path: "",
              name: translate("admin.management.groups.title"),
              component: () =>
                import("@/views/admin/management/groups/GroupsView.vue"),
            },
          ],
        },

        // notifications
        {
          path: "notifications",
          children: [
            {
              path: "",
              name: "header.admin.management.notifications",
              component: () =>
                import(
                  "@/views/admin/management/notifications/NotificationsView.vue"
                ),
            },
          ],
        },
        // dashboards
        {
          path: "dashboards",

          children: [
            {
              path: "",
              name: "header.admin.management.dashboards",
              component: () =>
                import("@/views/admin/management/dashboards/index.vue"),
            },
          ],
        },
        {
          path: "publications/",
          children: [
            {
              path: "",
              name: "publications",
              component: () =>
                import(
                  "@/views/admin/management/publications/PublicationsView.vue"
                ),
            },
            {
              path: "create",
              name: translate("admin.management.publications.create_title"),
              component: () =>
                import(
                  "@/views/admin/management/publications/CreatePublicationView.vue"
                ),
            },
            {
              path: "edit/:uuid",
              name: translate("admin.management.publications.edit_title"),
              component: () =>
                import(
                  "@/views/admin/management/publications/EditPublicationView.vue"
                ),
            },
          ],
        },
      ],
    },
    {
      path: "apis",
      meta: { onlyAdmin: true },

      children: [
        {
          path: "/admin/apis/integrations",
          name: "header.admin.apis.integration",
          component: () => import("@/views/admin/apis/ApisIntegrationView.vue"),
        },
        {
          path: "/admin/apis/reports",
          name: translate("apis_reports_page.title"),
          component: () => import("@/views/admin/apis/reports/ReportsView.vue"),
        },
        {
          path: "/admin/apis/integrations/edit/:uuid",
          name: "Editar integração",
          component: () => import("@/views/admin/apis/ConfigIntegration.vue"),
        },
        {
          path: "/admin/apis/configuration",
          meta: { onlyAdmin: true },
          children: [
            {
              path: "",
              name: "header.admin.apis.configuration",
              component: () =>
                import("@/views/admin/apis/ApisConfigurationView.vue"),
            },

            {
              path: "json",
              name: "json",
              component: () => import("@/views/admin/apis/IntegrationJSON.vue"),
            },
            {
              path: "xml",
              name: "xml",
              component: () => import("@/views/admin/apis/IntegrationXML.vue"),
            },
            {
              path: "excel",
              name: "excel",
              component: () =>
                import("@/views/admin/apis/IntegrationExcelFile.vue"),
            },
            {
              path: "integration-data",
              name: "integration data",
              component: () =>
                import(
                  "@/views/admin/apis/DefinePrimaryDataAndCollections.vue"
                ),
            },
          ],
        },
      ],
    },
    {
      path: "/admin/audits",
      meta: { onlyAdmin: true },

      name: "header.admin.audits",
      component: () => import("@/views/admin/audits/AuditsView.vue"),
    },
    {
      path: "configurations",
      children: [
        // questionnaires
        {
          path: "lov",
          name: "header.admin.configurations.lov.label",
          component: () => import("@/views/admin/lovs/LovsView.vue"),
        },
        {
          path: "countries",
          name: "header.admin.configurations.countries.label",
          component: () => import("@/views/admin/countries/CountriesView.vue"),
        },
        {
          path: "councils",
          name: "header.admin.configurations.councils.label",
          component: () => import("@/views/admin/councils/CouncilsView.vue"),
        },
        {
          path: "parishes",
          name: "header.admin.configurations.parishes.label",
          component: () => import("@/views/admin/parishes/ParishesView.vue"),
        },
        {
          path: "editable-texts",
          name: translate("editable_texts.title"),
          component: () =>
            import("@/views/admin/editable-texts/EditableTextsView.vue"),
        },
        {
          path: "indicators-cards",
          name: translate("indicators_cards.title"),
          component: () =>
            import("@/views/admin/indicator-cards/IndicatorCardsTabs.vue"),
        },
      ],
    },
  ],
};

export default routes_admin;
