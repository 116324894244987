import { createI18n } from "vue-i18n";
import pt from "../assets/i18n/pt.json";
import en from "../assets/i18n/en.json";

const matchedLanguage = window.location.search.match(/(\?|\&)l=(pt|en)($|\&)/g);
matchedLanguage &&
  sessionStorage.setItem("locale", matchedLanguage[0].slice(-2));

export const locale = sessionStorage.getItem("locale") || "pt";

const fallbackLocale = locale == "pt" ? "en" : "pt";
sessionStorage.setItem("locale", locale);

const i18n = createI18n({
  locale,
  fallbackLocale,
  messages: {
    pt: { ...pt },
    en: { ...en },
  },
});

export function getMoniturCurrentLanguage(): "pt" | "en" {
  return locale as any;
}

export function isMoniturCurrentLanguagePT(): boolean {
  return locale === "pt";
}

export default i18n;
