import i18n, { isMoniturCurrentLanguagePT } from "@/i18n";
import pt from "../assets/i18n/pt.json";
import en from "../assets/i18n/en.json";

const t = i18n.global;

export function editablesGetValue(key: string) {
  return t.tm("editable." + key) as any;
}
export function editablesGetValuesByPath(key: string) {
  return (t.tm("editable." + key) as any).values as string[];
}

export function editablesGetValueByPath(key: string) {
  return (t.tm("editable." + key) as any).values.join("") as string;
}

export function loginPageTranslateByKey(key: string) {
  return t.tm("login_page." + key) as any as string;
}

export function dimensionsOfSustainabilityTranslateByKey(key: string) {
  return t.tm("dimensions_of_sustainability." + key) as any as string;
}
export function getDimensionsOfSustainabilityByValue(value: string) {
  const enEntries = Object.entries(en["dimensions_of_sustainability"]);
  const ptEntries = Object.entries(pt["dimensions_of_sustainability"]);
  let result = value;

  if (isMoniturCurrentLanguagePT()) {
    ptEntries.find(([, v], i) => {
      if (v.trim() === value.trim()) {
        result = ptEntries[i][1];
        return true;
      }
    });
    return result;
  }

  ptEntries.find(([, v], i) => {
    if (v.trim() === value.trim()) {
      result = enEntries[i][1];
      console.log("!!");
      return true;
    }
  });

  return result;
}

export const translate = t.t;
