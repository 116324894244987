import { integrationsKeys } from "@/lib/integrations-localstorage";
import { defineStore } from "pinia";

export interface Collection {
  tableName: string;
  path: string;
  fields: string;
}
export interface PrimaryDataColumn {
  columnName: string;
  value: string;
}

export interface PrimaryData {
  tableName: string;
  columnsNames: PrimaryDataColumn[];
}

interface IntegrationFormData {
  url: string;
  pathToMainData: string;

  [k: string]: any;
}
interface IntegrationForm {
  id?: string;
  type: "API Rest" | "XML" | "Excel";
  data: IntegrationFormData;
  collections: Collection[];
  primaryData: PrimaryData;
  willExecute?: boolean;
}

interface IntegrationFinalization {
  frequency: {
    type: "mensal" | "anual";
    day: string;
    month: string;
  };
  name: string;
  description: string;
}

/**
 * !THIS STORE AND ITS DEPENDENT COMPONENTS MUST BE REFACTORED
 * !FOR THIS LOGIC TO GAIN FULL CONTROL OF * * THE INTEGRATION DATA BEING CREATED.
 */
export const useIntegrationCurrentFormStore = defineStore("integration", {
  state: () => ({
    integrationForm: JSON.parse(
      localStorage.getItem(integrationsKeys.currentIntegrationForm) || "{}"
    ) as IntegrationForm,
  }),
  actions: {
    resetIntegrationCurrentData() {
      this.integrationForm = {};
    },
    getIntegrationCurrentData() {
      return this.integrationForm as IntegrationForm;
    },
    setIntegrationTypeForm(
      type: IntegrationForm["type"],
      data: IntegrationForm["data"]
    ) {
      this.integrationForm.type = type;
      this.integrationForm.data = data;

      localStorage.setItem(
        "current-integration-form",
        JSON.stringify(this.integrationForm)
      );
    },
    defineInIntegrationTypeForm(data: IntegrationFinalization) {
      Object.assign(this.integrationForm.data, data);

      localStorage.setItem(
        "current-integration-form",
        JSON.stringify(this.integrationForm)
      );
    },
    defineInIntegration(data: any) {
      Object.assign(this.integrationForm, data);

      localStorage.setItem(
        "current-integration-form",
        JSON.stringify(this.integrationForm)
      );
    },
    setIntegrationPrimaryData(primaryData: any) {
      this.integrationForm.primaryData = primaryData;
    },
    setIntegrationCollections(collections: any[]) {
      this.integrationForm.collections = collections;
    },
    setIntegration(integration: any) {
      const {
        configuration,
        description,
        frequency,
        integration_type,
        name,
        status,
        id,
      } = integration || {};

      this.integrationForm.id = id;

      const {
        collections,
        day_month,
        day_to_repeat,
        execute_informations,
        path_to_main_data,
        primary_data,
      } = configuration;

      const { url, envelope, soap_action } = execute_informations;

      this.setIntegrationPrimaryData(primary_data);
      this.setIntegrationCollections(collections);

      if (integration_type === "API Rest") {
        localStorage.setItem(
          integrationsKeys.json,
          JSON.stringify({
            pathToMainData: path_to_main_data,
            url,
          })
        );
      } else if (integration_type === "XML") {
        localStorage.setItem(
          integrationsKeys.xml,
          JSON.stringify({
            pathToMainData: path_to_main_data,
            url,
            soapAction: soap_action,
            envelope,
          })
        );
      }

      const realFrequency = {
        type: frequency === "Anual" ? "anual" : "mensal",
        day: day_to_repeat ? day_to_repeat : (day_month ?? "").split("-")[0],
        month: day_to_repeat ? "" : (day_month ?? "").split("-")[1],
      };
      localStorage.setItem(
        integrationsKeys.integrationFrequency,
        JSON.stringify(realFrequency)
      );

      const integrationFinalization = {
        frequency: realFrequency,
        name,
        description,
      };

      localStorage.setItem(
        integrationsKeys.integrationFinalization,
        JSON.stringify(integrationFinalization)
      );

      const realCollections = (collections ?? []).map((i) => {
        return {
          ...i,
          fields: i.fields.join(";"),
          path: i.path,
          collectionName: i.table_name,
          tableName: i.table_name,
          isPathInvalid: false,
          invalidFields: [],
        };
      });
      localStorage.setItem(
        integrationsKeys.collectionsData,
        JSON.stringify({
          collectionsColumns: realCollections,
        })
      );

      const columnsNames = [];

      for (const key of Object.keys(primary_data ?? {})) {
        if (key !== "table_name") {
          columnsNames.push({
            columnName: key,
            value: primary_data[key],
            isPathInvalid: false,
          });
        }
      }

      const realPrimaryData = {
        tableName: (primary_data ?? {}).table_name,
        columnsNames: columnsNames,
      };

      localStorage.setItem(
        integrationsKeys.primaryData,
        JSON.stringify(realPrimaryData)
      );
    },
  },
});
