import i18n from "@/i18n";
import { defineStore } from "pinia";

const historyStorKey = "history-store";

const savedValue = localStorage.getItem(historyStorKey);

export const useHistoryStore = defineStore("routes-history", {
  state: () => ({
    routeHistory: savedValue ? JSON.parse(savedValue)?.h : [],
  }),
  actions: {
    addRouteToHistory(name: string, href: string) {
      if (name === "login" || name === "NotFound") return;

      const index = this.routeHistory.findIndex((v) => v.name === name);

      if (index > -1) {
        this.routeHistory.splice(index, 1);
      }

      this.routeHistory.push({
        title: i18n.global.t(name),
        href,
        name,
      });
    },
    getLastRoutesAcessed(amount = 6): Record<string, any>[] {
      const h = this.routeHistory.slice(-amount);

      localStorage.setItem(historyStorKey, JSON.stringify({ h }));
      return h;
    },
  },
});
