import { useIntegrationCurrentFormStore } from "@/stores/use-integration-store";

export const integrationsKeys = {
  json: "integration-json",
  integrationFinalization: "integration-finalization",
  integrationFrequency: "integration-frequency",
  integrationDateReference: "integration-date-reference",
  xml: "integration-xml",
  primaryData: "primary-data",
  collectionsData: "collections-data",
  currentIntegrationForm: "current-integration-form",
  mountTableTab: "mount-table-tab",
  integrationToExecuteData: "integration-to-execute-data",
  integrationToEditData: "integration-to-edit-data",
};

export function resetLocalStorageIntegrationsData() {
  Object.values(integrationsKeys).forEach((k) => {
    localStorage.removeItem(k);
  });

  useIntegrationCurrentFormStore().resetIntegrationCurrentData();
}
