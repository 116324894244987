import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import routes_public from "./routes-public";
import routes_admin from "./routes-admin";
import {
  hasUserLogged,
  isAdmin,
  isAdminOrInternal,
} from "@/lib/session-current-user";
import { useHistoryStore } from "@/stores/use-history-store";
import PageNotFound from "@/components/PageNotFound.vue";
import { translate } from "@/lib/i18n-helpers";

const routes: RouteRecordRaw[] = [
  routes_public, // Public routes
  // Logging
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/admin/TheLogin.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/public/HomeView.vue"),
  },
  {
    path: "/about",
    name: translate("header.about"),
    component: () => import("@/views/public/AboutView.vue"),
  },
  {
    path: "/download-questionnaire/:uuid",
    name: "download-questionnaire",
    component: () =>
      import("@/views/public/questionnaires/DownloadQuestionnaireView.vue"),
  },
  routes_admin, // Backoffice routes

  { path: "/:pathMatch(.*)*", name: "NotFound", component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export const initialLocationPathname = location.pathname;

router.beforeEach((to, _from, next) => {
  if (to.name === "login" && hasUserLogged())
    return next({ name: "dashboard" });

  if (!to.meta.requiresAuth) return next();
  if (!isAdminOrInternal() && !to.meta.external) return next("/");
  if (to.meta.onlyAdmin && !isAdmin()) return next("/");
  if (!hasUserLogged()) return next({ name: "login" });

  next();
});

router.beforeResolve((to, _f, next) => {
  const historyStore = useHistoryStore();

  if (typeof to.name === "string") {
    historyStore.addRouteToHistory(to.name, to.fullPath);
  }
  next();
});

export default router;
