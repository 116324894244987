import axios, { AxiosInstance } from "axios";
import type { App } from "vue";

interface AxiosOptions {
  baseUrl?: string;
  token?: string;
}

let axiosAPIInstance: AxiosInstance;

export function getSimpleAxiosInstance() {
  return axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export function getAxiosAPIInstance() {
  return axiosAPIInstance;
}

function setAuthorization(token: string) {
  return "Bearer " + token;
}

function getAuthToken(options: AxiosOptions = {}) {
  return !!options.token
    ? options.token
    : sessionStorage.getItem("token.access");
}

export default {
  install: (app: App, options: AxiosOptions = {}) => {
    const auth_token = getAuthToken();

    let countTryingToRefreshToken = 0;
    const maxTokenRefresh = 3;

    const api = axios.create({
      baseURL: process.env.VUE_APP_API_URL || options.baseUrl,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });

    axiosAPIInstance = api;

    /**
     * Hotfix: Add missing authentication token to requests.
     *
     * By applying this hotfix, we ensure that all requests include the necessary authentication token, * thereby maintaining the security and proper functioning of the application.
     */
    api.interceptors.request.use((config) => {
      api.defaults.headers.common["Authorization"] = setAuthorization(
        getAuthToken()
      );
      config.headers.Authorization = setAuthorization(getAuthToken());

      return config;
    });
    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response.status === 401) {
          countTryingToRefreshToken++;
        }

        if (countTryingToRefreshToken === maxTokenRefresh)
          return Promise.reject(error);

        // Check if the error is not an authentication error (401)
        if (error.response && error.response.status !== 401)
          return Promise.reject(error);

        return api
          .post("/users/refresh-token/", {
            refresh: sessionStorage.getItem("token.refresh"),
          })
          .then((response) => {
            const token = response.data.access;
            sessionStorage.setItem("token.access", token);

            // Update the authentication token with the new token received
            api.defaults.headers.common["Authorization"] =
              setAuthorization(token);

            error.config.headers.Authorization = setAuthorization(token);

            // Retry the original request
            return api(error.config);
          })
          .catch(() => {
            sessionStorage.clear();

            if (location.pathname === "/login") return;

            location.href = "/login";
          });
      }
    );

    app.config.globalProperties.$axios = api;
  },
};
