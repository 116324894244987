import { RouteRecordRaw } from "vue-router";

import AdminTemplate from "@/views/admin/_adminTemplateView.vue";
import { translate } from "@/lib/i18n-helpers";

const routes_public: RouteRecordRaw = {
  path: "/public",
  name: "public",
  component: AdminTemplate,
  children: [
    {
      path: "/publications/:category",
      name: translate("publications_pages.title"),
      component: () =>
        import("@/views/public/publications/PublicationsView.vue"),
    },
    {
      path: "/questionnaires",
      name: translate("questionnaires_pages.title"),
      component: () =>
        import("@/views/public/questionnaires/QuestionnairesView.vue"),
    },
    {
      path: "/questionnaires/:uuid",
      name: "questionnaire-respond",
      component: () =>
        import("@/views/public/questionnaires/AnswerQuestionnaireView.vue"),
    },
    {
      path: "/dimensions-of-sustainability",
      name: translate("dimensions_of_sustainability.page_title"),
      component: () =>
        import("@/views/public/dashboards/DimensionsOfSustainabilityView.vue"),
    },
  ],
};

export default routes_public;
