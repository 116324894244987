import { UserResponseModel } from "@/types/users";

const sessionUser = sessionStorage.getItem("user");

let user = (sessionUser ? JSON.parse(sessionUser) : {}) as UserResponseModel;

export function getSessionCurrentUser(): null | UserResponseModel {
  return user.profile_access ? user : null;
}

export function setSessionCurrentUser(u: null | UserResponseModel) {
  user = u;
  sessionStorage.setItem("user", JSON.stringify(u));
}

export function resetSessionCurrentUser() {
  sessionStorage.removeItem("user");
}

export function isAdmin() {
  return user.profile_access === "Admin";
}

export function isInternal() {
  return user.profile_access === "Interno";
}

export function isAdminOrInternal() {
  return isAdmin() || isInternal();
}

export function isExternal() {
  return user.profile_access === "Externo";
}
export function hasUserLogged() {
  return isAdminOrInternal() || isExternal();
}
