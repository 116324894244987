import { createApp } from "vue";
import axios, { getSimpleAxiosInstance } from "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import i18n, {
  getMoniturCurrentLanguage,
  isMoniturCurrentLanguagePT,
} from "./i18n";

import ModalComponent from "@/components/ModalComponent.vue";
import vuetify from "./plugins/vuetify";
import { pinia } from "./plugins/pinia";

import "devextreme/dist/css/dx.light.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-dashboard/dist/css/dx-dashboard.light.css";
import pt from "@/assets/i18n/pt.json";
import en from "@/assets/i18n/en.json";

import { ResourceManager } from "devexpress-dashboard";
import { locale } from "devextreme/localization";

const app = createApp(App)
  .use(router)
  .use(axios)
  .use(vuetify)
  .use(pinia)
  .component("ModalComponent", ModalComponent);

async function defineDevexpressLang() {
  const dxDashboardPT = await import(
    "@/assets/devexpress-localization/pt/dx-dashboard.pt.json"
  );
  const dxAnalyticsCorePT = await import(
    "@/assets/devexpress-localization/pt/dx-analytics-core.pt.json"
  );

  const culture = "pt";
  ResourceManager.setLocalizationMessages(dxDashboardPT.default);
  ResourceManager.setLocalizationMessages(dxAnalyticsCorePT.default);

  locale(culture);
  app.mount("#app");
}

getSimpleAxiosInstance()
  .post("/project-configurations/load-file/", {
    file_name: isMoniturCurrentLanguagePT()
      ? "monitur-pt.json"
      : "monitur-en.json",
  })
  .then((r) => {
    i18n.global.setLocaleMessage(getMoniturCurrentLanguage(), {
      ...(isMoniturCurrentLanguagePT() ? pt : en),
      editable: r.data,
    } as any);

    app.use(i18n);

    if (isMoniturCurrentLanguagePT()) {
      defineDevexpressLang();
    } else {
      app.mount("#app");
    }
  });
