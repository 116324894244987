import { defineStore } from "pinia";

export const useHeaderAndFooter = defineStore("header-and-footer", {
  state: () => ({
    footerView: true,
    headerView: true,
  }),
  actions: {
    removeThem() {
      this.footerView = false;
      this.headerView = false;
    },

    addThem() {
      this.footerView = true;
      this.headerView = true;
    },

    getState() {
      return { ...this };
    },
  },
});
