
import { defineComponent } from "vue";

import ButtonComponent from "@/components/ButtonComponent.vue";
import { translate } from "@/lib/i18n-helpers";

export default defineComponent({
  name: "ModalComponent",
  components: {
    ButtonComponent,
  },

  data: () => ({
    types: [
      "loader",
      "confirm",
      "success",
      "success-and-confirm",
      "error",
      "content",
    ],
    type: "loader",
    isVisible: false,
    title: undefined,
    message: undefined,
    subMessage: undefined,
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    translate,
    show(opts: any = {}) {
      this.type = opts.type || this.type;
      if (!this.types.includes(this.type)) {
        throw new Error("Invalid type: " + this.type);
      }

      if (this.type !== "loader") {
        this.message = opts.message || this.$t("admin.modal." + this.type);
        this.subMessage = opts.subMessage || "";
        if (this.type === "success" || this.type === "error") {
          this.title = this.$t(`admin.modal.${this.type}Title`);
        }
      }

      this.open();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    open() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    },

    closeModal() {
      if (this.type !== "loader") {
        this.close();
        this.resolvePromise(false);
      }
    },

    confirmModal() {
      this.close();
      this.resolvePromise(true);
    },
  },
});
